import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Sound, SoundConfig, SoundTypeEnum } from '../../shared/models/Sound';
import { BehaviorSubject } from 'rxjs';

export const SOUND_TOKEN = new InjectionToken<SoundConfig>('SoundConfig');

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  constructor(@Optional() @Inject(SOUND_TOKEN) soundConfig: SoundConfig) {
    this.soundData = soundConfig;
    this.soundData$ = new BehaviorSubject(this.soundData);
  }

  private audioContext = new AudioContext();
  private readonly soundData: SoundConfig | undefined;
  private readonly soundData$: BehaviorSubject<SoundConfig>;

  loadSound(soundData: SoundConfig) {
    this.soundData$.next(soundData);
  }

  async playSound(soundType: SoundTypeEnum) {
    if (!this.soundData$.value) {
      console.error('Sound data not loaded');
      return;
    }
    const soundCategory = this.soundData$.value.soundTypes.find(
      (type) => type.type === soundType,
    );
    if (!soundCategory) {
      console.error('Sound type not found');
      return;
    }
    const soundOptions = soundCategory.sounds;
    const soundToPlay =
      soundOptions[Math.floor(Math.random() * soundOptions.length)];

    if (soundToPlay.loop) {
      this.playLoop(soundToPlay);
    } else {
      this.playOnce(soundToPlay.filePath);
    }
  }

  private async playLoop(sound: Sound) {
    const audioBuffer = await this.fetchSound(sound.filePath);
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(this.audioContext.destination);

    // Convert milliseconds to seconds for the AudioContext
    const loopStartSeconds = sound.loopStart ? sound.loopStart / 1000 : 0;
    const loopEndSeconds = sound.loopEnd ? sound.loopEnd / 1000 : 0;
    const loopContinueMilliseconds = sound.loopContinue
      ? sound.loopContinue
      : 0;

    let currentTime = 0;
    const playSegment = (startTime: number, duration: number) => {
      const segmentSource = this.audioContext.createBufferSource();
      segmentSource.buffer = audioBuffer;
      segmentSource.connect(this.audioContext.destination);
      // startTime and duration need to be in seconds for the AudioContext API
      segmentSource.start(0, startTime, duration / 1000);
      return segmentSource;
    };

    // Play initial segment before the loop
    if (loopStartSeconds > 0) {
      playSegment(0, loopStartSeconds * 1000);
      currentTime += loopStartSeconds * 1000;
    }

    // Calculate loop duration in milliseconds
    const loopDuration = loopEndSeconds * 1000 - loopStartSeconds * 1000;
    // Schedule loops based on loopContinue
    const loopCount = Math.ceil(loopContinueMilliseconds / loopDuration);
    for (let i = 0; i < loopCount; i++) {
      setTimeout(() => {
        playSegment(loopStartSeconds * 1000, loopDuration);
      }, currentTime);
      currentTime += loopDuration;
    }

    // Schedule the final segment after the loop
    setTimeout(() => {
      this.playOnce(sound.filePath, loopEndSeconds);
    }, currentTime);
  }

  private playOnce(filePath: string, offset: number = 0) {
    // Fetch and play the sound file starting from offset (in seconds)
    this.fetchSound(filePath)
      .then((audioBuffer) => {
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.audioContext.destination);
        source.start(0, offset);
      })
      .catch((error) => {
        console.error('Error playing sound:', error);
      });
  }

  private async fetchSound(filePath: string): Promise<AudioBuffer> {
    const response = await fetch(filePath);
    const arrayBuffer = await response.arrayBuffer();
    return this.audioContext.decodeAudioData(arrayBuffer);
  }
}
