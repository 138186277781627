import { Injectable, Injector } from '@angular/core';
import { Performance } from '@angular/fire/performance';
import { trace } from '@firebase/performance';
import { NetworkMonitorService } from './network-monitoring.service'; // Assuming you have this service
import { Analytics, isSupported, logEvent } from '@angular/fire/analytics';
import { PerformanceTrace } from '@firebase/performance/dist/src/public_types';
import { addDoc, collection, Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class MonitoringSessionService {
  private currentSessionTrace: PerformanceTrace | null = null;
  private sessionData: {
    gameId: string;
    teamId?: string;
    dataUsed: number;
    wsDataSent: number;
    wsDataReceived: number;
    startTime: number;
    endTime?: number;
  } | null = null;

  constructor(
    private performance: Performance,
    private networkMonitor: NetworkMonitorService,
    private firestore: Firestore,
    private injector: Injector,
  ) {}

  /**
   * Starts a new session with the provided gameId.
   * @param gameId Unique identifier for the game.
   */
  startSession(gameId: string) {
    if (this.currentSessionTrace) {
      this.currentSessionTrace = null;
      this.sessionData = null;
    }

    // Initialize session data
    this.sessionData = {
      gameId,
      dataUsed: 0,
      wsDataSent: 0,
      wsDataReceived: 0,
      startTime: Date.now(),
    };

    // Start a custom trace
    this.currentSessionTrace = trace(this.performance, 'session_trace');
    this.currentSessionTrace.start();

    // Add custom attributes
    this.currentSessionTrace.putAttribute('gameId', gameId);

    console.log(`Session started for gameId: ${gameId}`);
  }

  /**
   * Ends the current session and logs the aggregated data.
   * @param teamId Unique identifier for the team.
   */
  async endSession(teamId: string) {
    if (!this.currentSessionTrace || !this.sessionData) {
      console.warn('No active session to end.');
      return;
    }

    // Update session end time
    this.sessionData.endTime = Date.now();

    // Aggregate data from other services
    this.sessionData.dataUsed = this.networkMonitor.getDataUsed();

    // Convert data to mega octets (megabytes)
    const dataUsedInMegaOctets = this.sessionData.dataUsed / (1024 * 1024);
    const wsDataSentInMegaOctets = this.sessionData.wsDataSent / (1024 * 1024);
    const wsDataReceivedInMegaOctets =
      this.sessionData.wsDataReceived / (1024 * 1024);

    // Log session data
    console.log('--- Session Summary ---');
    console.log(`Game ID: ${this.sessionData.gameId}`);
    console.log(`Team ID: ${this.sessionData.teamId}`);
    console.log(
      `Duration: ${this.sessionData.endTime - this.sessionData.startTime} ms`,
    );
    console.log(
      `Total Data Used: ${dataUsedInMegaOctets.toFixed(2)} mega octets`,
    );
    console.log(
      `WebSocket Data Sent: ${wsDataSentInMegaOctets.toFixed(2)} mega octets`,
    );
    console.log(
      `WebSocket Data Received: ${wsDataReceivedInMegaOctets.toFixed(2)} mega octets`,
    );

    this.currentSessionTrace.putAttribute('teamId', teamId);
    this.currentSessionTrace.putMetric('dataUsed', dataUsedInMegaOctets);
    this.sessionData.teamId = teamId;
    // Stop the trace
    this.currentSessionTrace.stop();

    if (await isSupported()) {
      const analytics = this.injector.get(Analytics);
      // Optional: Log to Firebase Analytics
      logEvent(analytics, 'session_end', {
        gameId: this.sessionData.gameId,
        teamId: this.sessionData.teamId,
        duration: this.sessionData.endTime - this.sessionData.startTime,
        dataUsed: dataUsedInMegaOctets,
      });
    }

    try {
      const sessionsCollectionRef = collection(this.firestore, 'sessions');
      await addDoc(sessionsCollectionRef, {
        gameId: this.sessionData.gameId,
        teamId: this.sessionData.teamId,
        duration: this.sessionData.endTime - this.sessionData.startTime,
        dataUsed: dataUsedInMegaOctets,
        timestamp: new Date(this.sessionData.endTime),
      });
      console.log('Session data saved to Firestore successfully.');
    } catch (error) {
      console.error('Error saving session data to Firestore:', error);
    }

    // Reset session data
    this.networkMonitor.resetDataUsed();
    this.currentSessionTrace = null;
    this.sessionData = null;
  }

  /**
   * Optionally, provide methods to get current session data.
   */
  getCurrentSessionData() {
    return this.sessionData;
  }
}
