import { Injectable } from '@angular/core';
import {
  ColorUtils,
  CommonRepositoryAbstract,
  PathParams,
  WithUid,
} from '@freddy/common';
import { InGameState } from '../stores/in-game.store';
import { Team } from '@freddy/models';
import {
  collection,
  doc,
  Firestore,
  QueryConstraint,
  where,
} from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamRepository extends CommonRepositoryAbstract<Team> {
  constructor(
    firestore: Firestore,
    private readonly store: Store,
  ) {
    super(firestore);
  }

  getTeamByUid(teamUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [where('uid', '==', teamUid)];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  getTeamByUserUid(userUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [
      where('userUid', '==', userUid),
    ];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  addTeam(teamData: WithUid<Omit<Team, 'color'>>): Observable<string> {
    return this.getCollections().pipe(
      take(1),
      map((teams) => teams.length),
      switchMap((teamCount) => {
        const assignedColor = ColorUtils.getColor(teamCount);
        const newTeamData: WithUid<Team> = {
          ...teamData,
          color: assignedColor,
          createdAt: new Date(),
          updatedAt: new Date(),
          currentState: 'AVAILABLE',
        };
        return this.set(newTeamData);
      }),
    );
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/teams`;
  }
}
