export class SelectOrCreateChatAction {
  static readonly type = '[Chat] Select or create chat';
  constructor(public readonly teamUid: string) {}
}

export class SaveMessageAction {
  static readonly type = '[Chat] Save message';
  constructor(public readonly message: string) {}
}

export class SelectChat {
  static readonly type = '[Chat] Select chat';

  constructor(public readonly chatUid: string) {}
}

export class ListenChatChangesAction {
  static readonly type = '[Chat] Listen chat changes';
}
