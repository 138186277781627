export interface SoundConfig {
  soundTypes: SoundType[];
}

export interface SoundType {
  type: SoundTypeEnum;
  sounds: Sound[];
}

export interface Sound {
  filePath: string;
  loop: boolean;
  loopStart?: number; // Optional because it's only relevant if loop is true
  loopEnd?: number; // Optional for the same reason
  loopContinue?: number; // The duration for which the loop should continue before playing the rest
}

export enum SoundTypeEnum {
  CLICK_BUTTON = 'click-button',
  CLICK_BUTTON_BACK = 'click-button-back',
  WARNING_CLICK = 'warning-click',
  CHECKING_DEVICE = 'checking-device',
  MINOR_ERROR = 'minor-error',
  PHOTO_TAKEN = 'photo-taken',
  ARRIVED_AT_DESTINATION = 'arrived-at-destination',
  EMOTICON_REACTION = 'emoticon-reaction',
  MESSAGE_SENT = 'message-sent',
  START_DUEL = 'start-duel',
  END_GAME = 'end-game',
  NEW_MESSAGE_RECEIVED = 'new-message-received',
  NEW_CHALLENGE_POSSIBLE = 'new-challenge-possible',
  TIMER_START = 'timer-start',
  CHALLENGE_WON = 'challenge-won',
  CHALLENGE_LOST = 'challenge-lost',
  MIC_STARTED = 'mic-started',
  TEAM_READY = 'team-ready',
  CORRECT_ANSWER = 'correct-answer',
  INCORRECT_ANSWER = 'incorrect-answer',
  LAUGH = 'laugh',
}
