import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  AuthenticateUserAction,
  DisconnectPlayerAction,
  FetchGameAction,
  GameIsFinishedAction,
  JoinGameWrongCodeAction,
  ListenCommandsActions,
  ListenRouteActions,
  LoadGameAssetsAction,
  RedirectToAdminAction,
  SelectLanguageAction,
  SetTeamStateAction,
  SetTenantAndOrganizationAction,
  SetUserDataAction,
} from '../../connect/actions/connect.actions';
import {
  FirebaseStorageService,
  GuidUtils,
  LocalStorageService,
  Throttle,
} from '@freddy/common';
import { Router } from '@angular/router';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { PositionService } from '../services/position.service';
import { Position } from '@capacitor/geolocation';
import {
  catchError,
  EMPTY,
  filter,
  firstValueFrom,
  from,
  Observable,
  of,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import {
  CreateNewTeamAction,
  GoToEndGameAction,
  GoToMapAction,
  GoToMissionsCompletedAction,
  JoinTeamAction,
  ListenAnswersAction,
  ListenGameAction,
  ListenTeamChallengesAction,
  ListenTeamsAction,
  ListenTeamsPositionsAction,
  ListenToPlayerPositionAction,
  OneTimeDisplayAction,
} from '../../game/actions/game.actions';
import { OrganizationAndTenant } from '../../connect/models/organization';
import { HotToastService } from '@ngneat/hot-toast';
import { TeamRepository } from '../repository/team.repository';
import { GameRepository } from '../repository/game.repository';
import { TeamWithPosition } from '../../game/containers/map-view/map-view.component';
import { ListenChatChangesAction } from '../../chat/actions/chat.actions';
import { Game, GameStatus, Team, TeamState, User } from '@freddy/models';
import { OneTimeDisplayEnum } from '../../game/config/one-time-display.enum';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';

import { MonitoringSessionService } from '../services/monitoring/monitoring-session.service';
import { NavController } from '@ionic/angular/standalone';
import { topToBottomTransition } from '../utils/animation.utils';
import {
  Database,
  off,
  onChildChanged,
  ref,
  set,
} from '@angular/fire/database';
import { patch, updateItem } from '@ngxs/store/operators';
import { environment } from '../../../environments/environment';

export const INGAME_STATE_TOKEN = new StateToken<InGameStateModel>('ingame');

export interface InGameStateModel {
  game?: Game;
  loading: boolean;
  gamePath?: string;
  currentTeamPath?: string;
  currentPosition?: Position;
  organization: OrganizationAndTenant;
  oneTimeDisplay: {
    [key: string]: boolean;
  };
  teams: Team[];
  user?: User;
}

@State<InGameStateModel>({
  name: INGAME_STATE_TOKEN,
  defaults: {
    loading: false,
    organization: {
      tenantId: null,
      organizationSlug: 'public',
    },
    oneTimeDisplay: {},
    teams: [],
  },
})
@Injectable()
export class InGameState {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly firebaseStorageService: FirebaseStorageService,
    private readonly positionService: PositionService,
    private readonly teamRepository: TeamRepository,
    private readonly gameRepository: GameRepository,
    private readonly localStorageService: LocalStorageService,
    private readonly monitoringSessionService: MonitoringSessionService,
    private toast: HotToastService,
    private navController: NavController,
    private database: Database,
  ) {}

  @Selector()
  static loading(state: InGameStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static userUid(state: InGameStateModel): string | undefined {
    return state.user?.uid;
  }

  @Selector()
  static hasStarted10MinutesAgo(state: InGameStateModel): boolean {
    if (!environment.production) {
      return true;
    }
    const currentTime = new Date();
    const gameStartTime = new Date(
      state.game!.plannedEndDate!.getTime() - state.game!.duration * 60000,
    );
    const tenMinutesAgo = new Date(currentTime.getTime() - 10 * 60000);
    return gameStartTime <= tenMinutesAgo;
  }

  @Selector()
  static plannedEndDate(state: InGameStateModel): Date | undefined | null {
    return state.game?.plannedEndDate;
  }

  @Selector()
  static status(state: InGameStateModel): GameStatus | undefined {
    return state.game?.status;
  }

  @Selector()
  static emergencyNumber(state: InGameStateModel): string | undefined {
    return state.game?.emergencyNumber;
  }

  @Selector()
  static gameIsDone(state: InGameStateModel): boolean {
    return (
      state.game?.status === GameStatus.DONE ||
      state.game?.status === GameStatus.STOPPED
    );
  }

  static mission(missionUid?: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      return game?.scenario.missions.find(
        (mission) => mission.uid === missionUid,
      );
    });
  }

  @Selector()
  static gamePath(state: InGameStateModel): string | undefined {
    return state.gamePath;
  }

  @Selector()
  static currentOrganizationSlug(state: InGameStateModel): string | undefined {
    return state.organization.organizationSlug;
  }

  @Selector()
  static teamPath(state: InGameStateModel): string | undefined {
    return state.currentTeamPath;
  }

  @Selector()
  static game(state: InGameStateModel): Game | undefined {
    return state.game;
  }

  @Selector()
  static teams(state: InGameStateModel): Team[] {
    return state.teams;
  }

  static team(teamUid: string) {
    return createSelector([InGameState.teams], (teams: Team[]) => {
      return teams.find((team) => team.uid === teamUid);
    });
  }

  static oneTimeDisplay(oneTimeDisplayKey: OneTimeDisplayEnum) {
    return createSelector([InGameState], (state: InGameStateModel) => {
      return state?.oneTimeDisplay[oneTimeDisplayKey];
    });
  }

  static specificChallenge(challengeUid: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      return game?.scenario?.challenges?.find(
        (challenge) => challenge.uid === challengeUid,
      );
    });
  }

  static specificAnswer(challengeUid: string) {
    return createSelector([InGameState.game], (game: Game | undefined) => {
      const challenge =
        game?.scenario?.challenges?.find(
          (challenge) => challenge.uid === challengeUid,
        ) ?? null;
      if (challenge) {
        // @ts-ignore
        return challenge.metadata[challenge.metadata.missionType]?.answer;
      }
      return null;
    });
  }

  @Selector()
  static currentPosition(state: InGameStateModel): Position | undefined {
    return state.currentPosition;
  }

  @Selector([InGameState.teams, InGameState.userUid])
  static myTeam(teams: Team[], userUid: string | undefined): Team | undefined {
    return teams.find((team) => team.userUid === userUid);
  }

  @Selector()
  static myTeamWithLatestPosition(
    state: InGameStateModel,
  ): TeamWithPosition | undefined {
    const myTeam = state.teams.find(
      (team) => team.userUid === state?.user?.uid,
    );
    return myTeam && state.currentPosition?.coords?.latitude
      ? {
          ...myTeam,
          currentPosition: state.currentPosition,
        }
      : undefined;
  }

  @Selector([InGameState])
  static othersTeamsWithPosition(
    state: InGameStateModel,
  ): TeamWithPosition[] | undefined {
    return state.teams
      .filter((team) => team.userUid !== state?.user?.uid)
      .filter(
        (team) => team.currentPosition && team.currentPosition?.coords.latitude,
      ) as TeamWithPosition[];
  }

  @Selector([InGameState])
  static otherTeams(state: InGameStateModel): Team[] {
    return state.teams.filter((team) => team.userUid !== state?.user?.uid);
  }

  @Selector([InGameState])
  static myRanking(state: InGameStateModel): number {
    return (
      this.teams(state)
        .sort((a, b) => (b.points ?? 0) - (a.points ?? 0))
        .findIndex((team) => team.userUid === state?.user?.uid) + 1
    );
  }

  @Selector([InGameState])
  static user(state: InGameStateModel): User | undefined {
    return state.user;
  }

  @Action(SetUserDataAction)
  setUserDataAction(
    ctx: StateContext<InGameStateModel>,
    action: SetUserDataAction,
  ) {
    ctx.patchState({
      user: action.user,
    });
  }

  @Action(SetTenantAndOrganizationAction)
  setTenantAndOrganizationAction(
    ctx: StateContext<InGameStateModel>,
    action: SetTenantAndOrganizationAction,
  ) {
    ctx.patchState({
      organization: action.organization,
    });
  }

  @Action(SetTeamStateAction)
  setTeamStateAction(
    ctx: StateContext<InGameStateModel>,
    action: SetTeamStateAction,
  ) {
    const teamUid = this.store.selectSnapshot(InGameState.myTeam)?.uid;
    if (teamUid) {
      ctx.setState(
        patch({
          teams: updateItem<Team>(
            (team) => team.uid === teamUid,
            patch({
              currentState: action.teamState,
              online: true,
            }),
          ),
        }),
      );
    }
    return;
  }

  @Action(FetchGameAction)
  joinGameAction(ctx: StateContext<InGameStateModel>, action: FetchGameAction) {
    let evaluator = false;
    let stripedGameCode;
    if (action.code.endsWith('ADM')) {
      evaluator = true;
      stripedGameCode = action.code.slice(0, -3);
    } else {
      stripedGameCode = action.code;
    }
    return this.gameRepository.fetchGame(stripedGameCode).pipe(
      switchMap((gameDoc) => {
        if (!gameDoc) {
          this.store.dispatch(new JoinGameWrongCodeAction());
          return EMPTY;
        } else {
          if (
            (gameDoc.data()?.status === GameStatus.STOPPED ||
              gameDoc.data()?.status === GameStatus.DONE) &&
            !evaluator
          ) {
            this.store.dispatch(new DisconnectPlayerAction());
            this.store.dispatch(new GameIsFinishedAction());
            return EMPTY;
          }
        }
        const organizationSlug = gameDoc.ref.parent.parent?.id;
        if (!organizationSlug) {
          console.error('Organization slug could not be determined');
          return EMPTY;
        }
        this.localStorageService.set('gameCode', action.code);
        return this.authenticateAndLoadGame(
          ctx,
          gameDoc,
          organizationSlug,
          evaluator,
        );
      }),
      catchError((error) => {
        this.toast.error(error.message);
        console.error('An error occurred:', error);
        return EMPTY; // Return an empty observable in case of an error
      }),
    );
  }

  @Action(ListenTeamsAction, { cancelUncompleted: true })
  listenTeamsAction(ctx: StateContext<InGameStateModel>) {
    const gamePath = ctx.getState().gamePath;

    if (!gamePath) {
      return;
    }
    const gameIsDone$ = this.store.select(InGameState.gameIsDone);
    return this.teamRepository.getCollectionsChanges().pipe(
      takeUntil(
        gameIsDone$.pipe(
          filter((isDone) => isDone),
          tap(() =>
            console.info(
              'Game is done, starting 30-minute timer for teams stream',
            ),
          ),
          switchMap(() => timer(30 * 60 * 1000)),
        ),
      ),
      filter((teams) => !!teams),
      map((teams) =>
        teams.map((team, index) => ({ ...team, teamNumber: index })),
      ),
      tap((updatedTeams) => {
        const currentTeams = ctx.getState().teams;
        const mergedTeams = updatedTeams.map((updatedTeam) => {
          const currentTeam = currentTeams.find(
            (t) => t.uid === updatedTeam.uid,
          );
          return {
            ...updatedTeam,
            currentPosition:
              currentTeam?.currentPosition || updatedTeam.currentPosition,
          };
        });

        console.debug('Updating state with teams:', mergedTeams);
        ctx.patchState({ teams: mergedTeams });
      }),
      finalize(() => {
        console.info('Unsubscribed from team updates');
      }),
    );
  }

  @Action(ListenGameAction, { cancelUncompleted: true })
  listenGameAction(
    ctx: StateContext<InGameStateModel>,
    action: ListenGameAction,
  ) {
    const gameUid = ctx.getState().game?.uid;
    if (gameUid) {
      return this.gameRepository.getDocChanges(gameUid).pipe(
        tap((game) => {
          console.trace('Received game update:', game);
          if (game) {
            console.trace('Updating game state');
            ctx.patchState({
              game: game,
            });
          } else {
            console.error(
              'Game update is null or undefined, not updating state',
            );
          }
        }),
      );
    }
    return;
  }

  @Throttle()
  @Action(CreateNewTeamAction)
  async createNewTeamAction(
    ctx: StateContext<InGameStateModel>,
    action: CreateNewTeamAction,
  ) {
    const game = ctx.getState().game;
    if (game) {
      const gamePath = ctx.getState().gamePath;
      if (gamePath) {
        ctx.patchState({
          loading: true,
        });
        const fileUpload = await this.firebaseStorageService.uploadPathBlob(
          `organizations/${
            ctx.getState().organization.organizationSlug
          }/games/${game.uid}`,
          action.teamPhotoPathBlob,
        );

        const customUid = GuidUtils.generateUuid();

        try {
          await firstValueFrom(
            this.teamRepository.addTeam({
              missionAccomplished: [],
              challengeAccomplished: [],
              missionBonusAccomplished: [],
              uid: customUid,
              userUid: ctx.getState().user!.uid,
              teamName: action.teamName,
              teamPhoto: fileUpload.filePath,
              currentState: 'AVAILABLE',
              online: true,
              lastSeen: new Date().getTime(),
            }),
          );

          return ctx.dispatch(new JoinTeamAction());
        } catch (error) {
          console.error('Error adding document: ', error);
        }
      } else {
        console.error('No game id has been set');
      }
    }
    return;
  }

  @Action(SelectLanguageAction)
  selectLanguageAction(
    ctx: StateContext<InGameStateModel>,
    action: SelectLanguageAction,
  ) {
    const teamUid = this.store.selectSnapshot(InGameState.myTeam)?.uid;
    if (teamUid) {
      return this.teamRepository.update({
        uid: teamUid,
        language: action.lang,
      });
    }
    return;
  }

  @Action(ListenTeamsPositionsAction, { cancelUncompleted: true })
  listenTeamsPositionsAction(ctx: StateContext<InGameStateModel>) {
    const gameId = ctx.getState().game?.uid;

    if (!gameId) {
      console.error('No game ID available, cannot listen for team positions');
      return;
    }

    const positionsRef = ref(this.database, `games/${gameId}/positions`);

    return new Observable<void>((observer) => {
      const onPositionChange = onChildChanged(positionsRef, (snapshot) => {
        const userId = snapshot.key;
        const position = snapshot.val();

        if (userId && position) {
          ctx.setState(
            patch({
              teams: updateItem<Team>(
                (team) => team.userUid === userId,
                patch({
                  currentPosition: position,
                  online: true,
                  lastSeen: new Date().getTime(),
                }),
              ),
            }),
          );
          console.trace(
            `Updated position for team ${userId} in game ${gameId}`,
            position,
          );
        }
      });

      return () => {
        off(positionsRef, 'child_changed', onPositionChange);
        console.info(
          `Unsubscribed from team position updates for game ${gameId}`,
        );
      };
    });
  }

  @Action(ListenRouteActions, { cancelUncompleted: true })
  listenRouteActions(ctx: StateContext<InGameStateModel>) {
    const gameId = ctx.getState().game?.uid;

    if (!gameId) {
      console.error('No game ID available, cannot listen for team positions');
      return;
    }

    const positionsRef = ref(this.database, `games/${gameId}/status`);

    return new Observable<void>((observer) => {
      const onPositionChange = onChildChanged(positionsRef, (snapshot) => {
        const userUid = snapshot.key;
        const status: { currentPath: string; currentState: TeamState } =
          snapshot.val();

        if (userUid && status) {
          ctx.setState(
            patch({
              teams: updateItem<Team>(
                (team) => team.userUid === userUid,
                patch({
                  ...status,
                  online: true,
                  lastSeen: new Date().getTime(),
                }),
              ),
            }),
          );
          console.trace(
            `Updated status for team ${userUid} in game ${gameId}`,
            status,
          );
        }
      });

      return () => {
        off(positionsRef, 'child_changed', onPositionChange);
        console.info(
          `Unsubscribed from team status updates for game ${gameId}`,
        );
      };
    });
  }

  @Action(ListenToPlayerPositionAction, { cancelUncompleted: true })
  listenToPlayerPositionAction(
    ctx: StateContext<InGameStateModel>,
    action: ListenToPlayerPositionAction,
  ) {
    const gameId = ctx.getState().game?.uid;

    const myTeam$ = this.store.select(InGameState.myTeam);

    return this.positionService.getPosition(1000).pipe(
      withLatestFrom(myTeam$),
      filter(([position, myTeam]) => !!myTeam?.userUid),
      tap(([position, myTeam]) => {
        if (!myTeam || !position || !position.coords) {
          console.log('Invalid position received, skipping update');
          return;
        }
        const positionRef = ref(
          this.database,
          `games/${gameId}/positions/${myTeam.userUid}`,
        );
        set(positionRef, {
          timestamp: position.timestamp,
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
          },
        });
        ctx.patchState({ currentPosition: position });
        console.trace('Updated state with new position', position);
      }),
      finalize(() => {
        console.log('Unsubscribed from position updates');
      }),
    );
  }

  @Action(JoinTeamAction)
  joinTeamAction(
    ctx: StateContext<InGameStateModel>,
    action: JoinTeamAction,
  ): Observable<any> {
    const gamePath = ctx.getState().gamePath;
    const game = ctx.getState().game;
    const userUid = ctx.getState().user?.uid;
    if (!gamePath || !game || !userUid) return of();

    // Determine which team to join based on the action's payload or the current user's UID
    const team$ = action.team
      ? this.teamRepository.getTeamByUid(action.team.uid)
      : this.teamRepository.getTeamByUserUid(userUid);

    return team$.pipe(
      map((team) => {
        if (team) {
          const teamPath = gamePath + '/teams/' + team.uid;
          console.log('Joining team: ', teamPath, 'with user: ', userUid);
          ctx.patchState({
            currentTeamPath: teamPath,
            loading: false,
          });

          // Dispatching actions to listen for various game updates
          this.dispatchUpdateActions(team);

          // Updating the team document
          return from(
            this.teamRepository.update({
              uid: team.uid,
              userUid: userUid,
              online: true,
              lastSeen: new Date().getTime(),
            }),
          );
        }
        return;
      }),
      switchMap(() => {
        const updatedState = ctx.getState();
        const newPath =
          updatedState.currentTeamPath && game.status === GameStatus.ONGOING
            ? ['game', game.code, game.companyLogo ? 'intro' : 'map']
            : ['game', game.code, 'teams'];
        return from(this.router.navigate(newPath));
      }),
    );
  }

  @Action(GoToMapAction)
  goToMapAction(ctx: StateContext<InGameStateModel>, action: GoToMapAction) {
    const game = ctx.getState().game;
    return from(
      this.navController.navigateBack(['game', game?.code, 'map'], {
        animation: topToBottomTransition,
      }),
    );
  }

  @Action(GoToEndGameAction)
  async goToEndGameAction(
    ctx: StateContext<InGameStateModel>,
    action: GoToEndGameAction,
  ) {
    const game = ctx.getState().game;
    await this.monitoringSessionService.endSession(
      this.store.selectSnapshot(InGameState.myTeam)!.uid,
    );
    return from(this.router.navigate(['game', game?.code, 'end']));
  }

  @Action(GoToMissionsCompletedAction)
  goToMissionsCompletedAction(
    ctx: StateContext<InGameStateModel>,
    action: GoToMissionsCompletedAction,
  ) {
    const game = ctx.getState().game;
    return from(
      this.router.navigate(['game', game?.code, 'missions-completed']),
    );
  }

  @Action(OneTimeDisplayAction)
  oneTimeDisplayAction(
    ctx: StateContext<InGameStateModel>,
    action: OneTimeDisplayAction,
  ) {
    const state = ctx.getState();
    state.oneTimeDisplay[action.key] = true;
    ctx.patchState({
      oneTimeDisplay: state.oneTimeDisplay,
    });
  }

  private dispatchUpdateActions(team: Team) {
    this.store.dispatch(new ListenAnswersAction());
    this.store.dispatch(new ListenTeamChallengesAction());
    this.store.dispatch(new ListenChatChangesAction());
    this.store.dispatch(new ListenCommandsActions(team));
    this.store.dispatch(new ListenRouteActions());
  }

  private authenticateAndLoadGame(
    ctx: StateContext<InGameStateModel>,
    gameDoc: QueryDocumentSnapshot<Game>,
    organizationSlug: string,
    evaluator = false,
  ): Observable<any> {
    return evaluator
      ? this.store.dispatch(
          new RedirectToAdminAction(gameDoc.data(), organizationSlug),
        )
      : this.store.dispatch(new AuthenticateUserAction(organizationSlug)).pipe(
          switchMap(() => {
            const game = gameDoc.data();
            ctx.patchState({
              game: game,
              gamePath: gameDoc.ref.path,
            });
            this.monitoringSessionService.startSession(game.uid);
            return this.store.dispatch([
              new LoadGameAssetsAction(game),
              new ListenTeamsAction(),
              new ListenGameAction(),
              new ListenToPlayerPositionAction(),
              new ListenTeamsPositionsAction(),
            ]);
          }),
        );
  }
}
